import React from 'react'
import EC from "elliptic";
import CircularProgress from "@mui/material/CircularProgress";
import {Modal} from "react-bootstrap";

class WebReceiverSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      search_value: null,
      display_record_viewer: false,
      shared_secret: null,
      exchange_id: null,
      report_data: null,
      configuration: null,
      question_contents: null,
      referral_questions:null,
      organizations: null,
      show_about_help_modal: false,
      about_clicked: false,
      report_incident_number: "",
      loading: false,
      pdf_loading: false,
      pdf_loaded: false,
      access_code_error: false
    };
    this.wait_for_data_timeout = 0;
    this.pdfBlob = null;
    this.pdfBlobURL = null;
  }

  handleHideAboutHelpModal = () => {
    this.setState({
      show_about_help_modal: false
    })
  }

  handleShowAboutHelpModal = (about_clicked) => {
    this.setState({
      show_about_help_modal: true,
      about_clicked: about_clicked
    })
  }

  onChange = (e) => {
    e.target.value = e.target.value.toUpperCase()

    if(!this.state.search_value || e.target.value.length > this.state.search_value.length){
      var val = e.target.value.replace(/-/g,"");
      var temp = ""
      for (var i = 0; i < val.length; i++) {
        if (i !== 0 && (i + 1) % 3 == 0 && i < 8) {
          temp += val[i] + "-"
        } else {
          temp += val[i]
        }

      }
      e.target.value = temp
    }
    this.setState({
      search_value: e.target.value
    });
  };

  onPaste = (e) => {
    e.preventDefault()
  }

  keyPressed = (e) => {
    if (e.key === "Enter") {
      this.search()
    } else {
      const re = /[0-9a-zA-Z]+/g;
      if (!re.test(e.key)) {
        e.preventDefault();
      }

      if (e.target.value.length > 10) {
        e.preventDefault()
      }
    }
  }

  handlePDFDownload = () => {
    var date = getFullFormattedTitleDateString(new Date(), this.state.configuration)
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(this.pdfBlob, "HealthIM Report - " + date + ".pdf");
    } else {
      var link = $('#download-link');
      link.attr('href', this.pdfBlobURL);
      link.attr('download', "HealthIM Report - " + date + ".pdf");
      link.click();
    }
  }

  search = () => {
    var self = this;
    self.setState({
      loading: true,
      access_code_error: false
    })
    $.ajax({
      url: '/web_receiver/search.json?search_parameter=' + this.state.search_value,
      method: 'GET'
    }).done(function (data) {
      if (data.exchange) {
        try {
          var ec = new EC.ec('p521');
          var key1 = ec.keyFromPublic(data.exchange.sender_public_key, 'hex');
          var key2 = ec.genKeyPair();

          self.setState({shared_secret: key2.derive(key1.getPublic()), exchange_id: data.exchange.id});
          $.ajax({
            url: '/web_receiver/acknowledge.json',
            method: 'POST',
            data: {
              exchange_id: data.exchange.id,
              receiver_public_key: key2.getPublic().encode('hex')
            }
          }).done(function (data) {
            self.wait_for_data_timeout = 0;
            self.checkForSend();
          }).fail(function () {
            self.setState({
              loading: false,
              access_code_error: true
            })
          })
        }
        catch(err) {
          console.log(err);
          self.setState({
            loading: false,
            access_code_error: true
          })
        }
      }
      else{
        self.setState({
          loading: false,
          access_code_error: true
        })
      }
    }).fail(function () {
      self.setState({
        loading: false,
        access_code_error: true
      })
    })
  };

  componentDidUpdate(prevProps) {
    var self = this;
    if (self.state.display_record_viewer && self.state.pdf_loading == false && self.state.pdf_loaded == false) {
      self.renderPDF();
    }
  }

  checkForSend = () => {
    var self = this;
    $.ajax({
      url: '/web_receiver/check_for_send.json?exchange_id=' + this.state.exchange_id,
      method: 'GET'
    }).done(function (data) {
      if (data.exchange) {
        try{
          var ciphertext = data.exchange.ciphertext;
          var iv = data.exchange.initial_value;
          var salt = data.exchange.salt;
          var authentication_tag = data.exchange.authentication_tag;

          var key = forge.pkcs5.pbkdf2(self.state.shared_secret.toString(16, 4), atob(salt), 14271, 32, forge.md.sha512.create());

          // decrypt some bytes using GCM mode
          var decipher = forge.cipher.createDecipher('AES-GCM', key);
          decipher.start({
            iv: atob(iv),
            tag: atob(authentication_tag) // authentication tag from encryption
          });
          decipher.update(forge.util.createBuffer(atob(ciphertext)));
          var pass = decipher.finish();

          if (pass) {
            var reportObject = data.report;
            var decryptedJSON = JSON.parse(decipher.output.toString());
            var configuration = data.configuration;
            var questions = data.question_contents;
            var organizations = data.organizations;

            for (var key in decryptedJSON) {
              reportObject[key] = decryptedJSON[key];
            }
            self.setState({
              display_record_viewer: true,
              report_data: reportObject,
              configuration: configuration,
              question_contents: questions,
              organizations: organizations,
              report_incident_number: reportObject["incidentNumber"],
              loading: false,
              access_code_error: false
            });
          }
          else{
            self.loadError(self.state.exchange_id)
          }
        } catch(err) {
          console.log(err);
          self.loadError(self.state.exchange_id)
        }
      } else {
        if(self.wait_for_data_timeout == 20){
          self.loadError(self.state.exchange_id)
        }
        else{
          self.wait_for_data_timeout += 1;
          setTimeout(self.checkForSend, 3000);
        }

      }
    }).fail(function () {
      self.loadError(self.state.exchange_id)
    })
  };

  loadError = (exchange_id) => {
    var self = this;
    $.ajax({
      url: '/web_receiver/receiver_error.json',
      method: 'POST',
      data: {
        exchange_id: exchange_id
      }
    });
    self.setState({
      loading: false,
      access_code_error: true
    })
  };

  renderPDF = () => {
    var self = this;
    self.pdfBlob = null;
    self.pdfBlobURL = null;
    self.setState({pdf_loading: true})

    forge.options.usePureJavaScript = true;
    var reportObject = this.state.report_data;
    var contentJSON = this.state.question_contents;
    var configData = this.state.configuration;
    var organizationsList;
    var contentDictionary;
    var PDFs;

    setCanvas('pdf-canvas');

    if (configData.bmhsTextReplace) {
      var bmhs_override = configData.bmhsTextReplace;
      Object.keys(bmhs_override).forEach(function (key) {
          var bmhs_override_section = bmhs_override[key]
          for (var val in bmhs_override_section) {
            var index = bmhs_override_section[val]["index"];

            if (bmhs_override_section[val]["originalOptionText"] && bmhs_override_section[val]["newOptionText"] !== undefined) {
              var re = new RegExp(bmhs_override_section[val]["originalOptionText"], 'g');
              for (var i in contentJSON[key][index]["options"]) {
                contentJSON[key][index]["options"][i] = contentJSON[key][index]["options"][i].replace(re, bmhs_override_section[val]["newOptionText"])
              }
            }

            if (bmhs_override_section[val]["originalButtonText"] && bmhs_override_section[val]["newButtonText"] !== undefined) {
              var re = new RegExp(bmhs_override_section[val]["originalButtonText"], 'g');
              for (var i in contentJSON[key][index]["buttons"]) {
                contentJSON[key][index]["buttons"][i]["text"] = contentJSON[key][index]["buttons"][i]["text"].replace(re, bmhs_override_section[val]["newButtonText"])
              }
            }
          }
        }
      )
    }

    organizationsList = this.state.organizations;
    var sectionBContent = contentJSON["sectionBContent"];
    var sectionCContent = contentJSON["sectionCContent"];
    var sectionDActions = contentJSON["sectionDActions"];
    contentDictionary = {
      sectionBContent: sectionBContent,
      sectionCContent: sectionCContent,
      sectionDActions: sectionDActions,
    };

    PDFs = HealthIMPDFGeneration(configData, organizationsList, contentDictionary, '/assets/', null, 'HC');

    var doc = new PDFDocument({margins: {right: 0}, bufferPages: true});

    var stream = doc.pipe(blobStream());

    var indicatorArray = PDFs.indicatorsArray(reportObject);

    if (reportObject['lastReport']) {
      contentDictionary.lastReportIndicators = PDFs.indicatorsArray(reportObject['lastReport']);
    }
    PDFs.configureChart(reportObject, chartOnComplete);

    function chartOnComplete() {
      try{
        PDFs.generatePDF(doc, reportObject, indicatorArray, contentDictionary);
        doc.end();
      }
      catch(e){
        console.log(e);
        self.loadError(self.state.exchange_id)
      }

      if(self.state.access_code_error == false){
        $.ajax({
          url: '/web_receiver/complete_transmission.json',
          method: 'POST',
          data: {
            exchange_id: self.state.exchange_id
          }
        });
        
        stream.on('finish', function () {
          var arrayBuffer;
          var fileReader = new FileReader();
          fileReader.onload = function (event) {
            arrayBuffer = event.target.result;
            var loadingTask = pdfjsLib.getDocument({data: arrayBuffer});
            loadingTask.promise.then(function (pdfDoc_) {
              pdfDoc = pdfDoc_;
              numberOfPages = pdfDoc.numPages;
              // Initial/first page rendering
              renderPage(currentPage);
              pdfZoom(-2);
            });
          };
          self.pdfBlob = this.toBlob();
          self.pdfBlobURL = this.toBlobURL();
          fileReader.readAsArrayBuffer(self.pdfBlob);
          self.setState({pdf_loading: false, pdf_loaded: true})
        })
      }
    }
  };

  handleBackClick = () => {
    this.setState({
      search_value: null,
      display_record_viewer: false,
      shared_secret: null,
      exchange_id: null,
      report_data: null,
      configuration: null,
      question_contents: null,
      organizations: null,
      report_incident_number: "",
      show_about_help_modal: false,
      about_clicked: false,
      loading: false,
      pdf_loading: false,
      pdf_loaded : false,
      access_code_error: false
    });

    this.pdfBlob = null;
    this.pdfBlobURL = null;
    canvasReset()
  };

  componentDidMount() {
    canvasReset()
    this.pdfBlob = null;
    this.pdfBlobURL = null;
  }

  componentWillUnmount() {
    canvasReset()
    this.pdfBlob = null;
    this.pdfBlobURL = null;
  }

  render() {
    if (this.state.display_record_viewer) {
      return (
        <div>
          <div id="re-reports-container" className="web-receiver-report-container">
            <div className="web-receiver-report-header">
              <div id="report-view-button-container">
                <div className="record-editor-button web-receiver-navigation-text web-receiver-button button-large"
                     onClick={() => {
                       this.handleBackClick()
                     }}
                >
                  Cancel
                </div>
              </div>

              <div id='report-view-pdf-control-container' className="web-receiver-download-button-container">
                <a id="download-link">
                  <div className="record-editor-edit web-receiver-navigation-text web-receiver-button button-large"
                       onClick={() => {
                         this.handlePDFDownload()
                       }}>
                    Download
                  </div>
                </a>
              </div>

              <div id="web-receiver-view-control-container">
                <div id="web-receiver-page-control-container">
                  <div id="web-receiver-page-label" className="web-receiver-navigation-text">
                    Page:
                  </div>
                  <div id="report-view-back-button-container"
                       className="record-editor-button button-medium web-receiver-button"
                       onClick={() => {
                         previousPage()
                       }}>
                    <img id="web-receiver-back-button" src={this.props.page_control_button_path}/>
                  </div>
                  <div id="viewer-page-count" className="web-receiver-navigation-text">
                    <CircularProgress
                      size={20}
                      thickness={5}
                      color="inherit"
                      classes={{
                        svg: 'view_report_spinner'
                      }}
                    />
                  </div>
                  <div id="report-view-forward-button-container"
                       className="record-editor-button button-medium web-receiver-button"
                       onClick={() => {
                         nextPage()
                       }}>
                    <img id="web-receiver-forward-button" src={this.props.page_control_button_path}/>
                  </div>
                </div>
                <div id="web-receiver-zoom-control-container">
                  <div id="viewer-page-count" className="web-receiver-navigation-text">
                    Zoom:
                  </div>
                  <div id='report-view-increase-button'
                       className="record-editor-button button-medium web-receiver-navigation-text web-receiver-button"
                       onClick={() => {
                         pdfZoom(1)
                       }}>
                    +
                  </div>
                  <div id='report-view-decrease-button'
                       className="record-editor-button button-medium web-receiver-navigation-text web-receiver-button"
                       onClick={() => {
                         pdfZoom(-1)
                       }}>
                    -
                  </div>
                </div>
              </div>
            </div>
          </div>
          <canvas id="pdf-canvas" width="90%" className="web-receiver-pdf"/>
          {this.state.pdf_loading && <div id="web-receiver-spinner-container">
            <img
              id="web-receiver-spinner" src={this.props.loading_animation_path}
            />
          </div>}
        </div>
      )
    } else {
      return (
        <div id="web-receiver-container">
          <div className="web-receiver-logo-container">
            <img id="web-receiver-healthIM-logo" src={this.props.healthim_logo_path}/>
            <p id="web-receiver-logo-container-sub-title"
               onClick={() => {
                 this.handleShowAboutHelpModal(true)
               }}
            >About</p>
          </div>
          <div id="web-receiver-login-container">
            <div id="web-receiver-login-header">
              <h2 id="web-receiver-login">ACCESS BMHS</h2>
              <WebReceiverLoginInfo
                access_code_error={this.state.access_code_error}
              />
            </div>

            <div id="web-receiver-login-form">
              <h2 className="web-receiver-field-heading">Enter 9 Digit Access Code:
              </h2>
              <div className="web-receiver-field">
                <input type="text" placeholder="enter code"
                       className={`web-receiver-login-input ${this.state.access_code_error ? 'error-field' : ''}`}
                       onKeyPress={this.keyPressed}
                       onChange={this.onChange}
                       onPaste={this.onPaste}/>
              </div>
              <br/>
              <br/>
              <div className="web-receiver-field">
                <button id="web-receiver-login-button" disabled={!this.state.search_value}
                        onClick={this.search}>SUBMIT
                </button>
              </div>
            </div>
          </div>
          <br/>
          <br/>
          <div className="web-receiver-logo-container">
            <p id="web-receiver-logo-container-sub-title-center"
               onClick={() => {
                 this.handleShowAboutHelpModal(false)
               }}
            >Need help accessing a record?</p>
          </div>
          <AboutHelpModal
            show_about_help_modal={this.state.show_about_help_modal}
            handleHideAboutHelpModal={this.handleHideAboutHelpModal}
            about_clicked={this.state.about_clicked}
            healthim_logo_path={this.props.healthim_logo_path}
          />
          {this.state.loading && <div id="web-receiver-spinner-container">
            <img
              id="web-receiver-spinner" src={this.props.loading_animation_path}
            />
          </div>}
        </div>
      );
    }
  }
}

function WebReceiverLoginInfo(props) {
  if (props.access_code_error) {
    return (
      <div>
        <p className="web-receiver-login-sub-header error-text">Invalid Access Code</p>
        <p className="web-receiver-login-sub-header">The access code you entered was not recognized.</p>
        <p className="web-receiver-login-sub-header">Please try again.</p>
      </div>
    )
  } else {
    return (
      <div>
        <span className="web-receiver-login-sub-header">
          <div className="web-receiver-login-sub-header">To access a BMHS Record enter</div>
          <div className="web-receiver-login-sub-header highlight"> 9 digit access code.</div>
        </span>
        <p className="web-receiver-login-sub-header">(The access code will be provided by the responder)</p>
      </div>
    )
  }
}

function AboutHelpModal(props) {
    return (
      <div>
        <Modal id="web-receiver-modal"
               bsPrefix={"web-receiver-modal"}
               backdropClassName="web-receiver-modal-backdrop"
               show={props.show_about_help_modal} backdrop={true} keyboard={false} animation={false}
               onHide={props.handleHideAboutHelpModal} dialogClassName={"re-modal-content"}>
          <div id="web-receiver-info-title-container">
            <img id="web-receiver-healthIM-info-logo" className="info-logo" src={props.healthim_logo_path}/>
            <p id="web-receiver-logo-info-title" className="info-title">About HealthIM</p>
          </div>
          <br/>
          <div id="archive_modal_text_container" className="web-receiver-info-body">
            <div className="archive_modal_text_body_container">
              <p className="web-receiver-info-text header">
                What is HealthIM?
              </p>
              <span className="web-receiver-info-text">
                Emergency first responders use the HealthIM digital public safety system when responding to patients in community who are in an acute state of crisis. Responders review a pre-response safety briefing and then conduct a brief evidence-based mental health risk assessment, the interRAI™ Brief Mental Health Screener (BMHS). If responding personnel determine a patient must be taken to an acute care facility under the provincial mental health legislation a short clinical summary PDF is automatically generated.
              </span>
            </div>

            <br/>
            <div className="archive_modal_text_body_container">
              <p className="web-receiver-info-text header">
                What is a clinical summary PDF?
              </p>
              <span className="web-receiver-info-text">
                To support the transition of care from emergency personnel to an acute care setting, clinical summary PDFs are automatically generated and encrypted. These records summarize the responder’s observations and decisions on scene as well as any nursing workplace violence prevention cautions or other notes. The clinical summary PDF also summarizes any recent emergency contact with the patient if a HealthIM BMHS risk assessment was completed by responding personnel within the province.
              </span>
            </div>

            <br/>
            <div className="archive_modal_text_body_container">
              <p className="web-receiver-info-text header">
                What is the web receiver?
              </p>
              <span className="web-receiver-info-text">
                Emergency first responders are able to transmit clinical summary PDFs to acute care destinations prior to their departure from scene. For an acute care facility to receive these summaries a HealthIM autoprint receiving system must be established and securely connected to the local HealthIM emergency response network. In the event of network transmission error, emergency personnel can trigger a backup transmission to this web receiver. For acute care sites not connected to the local HealthIM emergency response network, healthcare personnel are able to access a clinical summary PDF from this web receiver.
              </span>
            </div>

            <br/>
            <div className="archive_modal_text_body_container">
              <p className="web-receiver-info-text header">
                Where can I find the nine-digit access code?
              </p>
              <span className="web-receiver-info-text">
                Responding emergency personnel escorting a patient in acute crisis have the ability to make clinical summary PDF reports available via this web receiver. Responders locally encrypt the clinical summary PDF on their duty-issue mobile device and then make the report available for authorized access by healthcare personnel at receiving acute care sites. Responders are provided with a temporary access nine-digit authentication code which they verbally relay to healthcare personnel at the acute care destination.
              </span>
            </div>

            <br/>
            <div className="archive_modal_text_body_container">
              <p className="web-receiver-info-text header">
                I downloaded the clinical summary record, where can I access it?
              </p>
              <span className="web-receiver-info-text">
                After keying in the nine-digit access code provided by an emergency responder escorting a patient to your acute care facility, the clinical summary PDF will be temporarily downloaded and made available for viewing and/or printing.
              </span>
            </div>

          </div>
          <div id="archive_modal_button_container" className="web-receiver-button-container">
            <button id="archive_modal_cancel_button" className="automated-button"
                    onClick={props.handleHideAboutHelpModal}>Close
            </button>
          </div>
        </Modal>
      </div>
    );

}


export default WebReceiverSearch